import axios from "axios"

let Base_URL = "https://api.green.shopiads.de/Shopiads" //development server url
let Base_URL_US = "https://api.green.shopiads.co.uk/Shopiads" //development server url
//const bucketURL = 'https://assets.shopiads.ca'


let pathName=""
if(window!=undefined&&window.location!=undefined)
 pathName=window.location.pathname;

 console.log("path name=================",window.location.host);

if(window.location.host!=undefined&& window.location.host.includes("de"))
{
  Base_URL = "https://api.green.shopiads.de/Shopiads";
Base_URL_US = "https://api.green.shopiads.co.uk/Shopiads"
}
else
{
  Base_URL = "https://api.green.shopiads.co.uk/Shopiads";
Base_URL_US = "https://api.green.shopiads.de/Shopiads"
}

let headers = {
  "Content-Type": "application/json",
}

let Auth_api = axios.create({
  baseURL: Base_URL,
  headers,
})

let Auth_api_us = axios.create({
  baseURL: Base_URL_US,
  headers,
})

Auth_api.interceptors.request.use(
  (config) => {
    // perform a task before the request is sent
    console.log("Request was sent")
    return config
  },
  (error) => {
    // handle the error
    return Promise.reject(error)
  }
)

Auth_api.interceptors.response.use(
  (response) => {
    // do something with the response data
    console.log("Response was received")
    return response
  },
  (error) => {
    // handle the response error
    return Promise.reject(error)
  }
)

Auth_api_us.interceptors.request.use(
  (config) => {
    // perform a task before the request is sent
    console.log("Request was sent")
    return config
  },
  (error) => {
    // handle the error
    return Promise.reject(error)
  }
)

Auth_api_us.interceptors.response.use(
  (response) => {
    // do something with the response data
    console.log("Response was received")
    return response
  },
  (error) => {
    // handle the response error
    return Promise.reject(error)
  }
)

const encodedUserId = (userId) => {
  var encodedData = btoa(userId)
  console.log("encodedData>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>",encodedData);
  return encodedData
}

const API = {

 bucketURL: 'https://shopiadsimages.blob.core.windows.net/storeimages/',
 //fullBucketURL: 'https://shopiadsimages.blob.core.windows.net/storeimages/images/',
 //fullBucketURL: 'https://shopiadsimages.blob.core.windows.net/storeimages/imagesProd/',
 fullBucketURL: 'https://shopiadsimages.blob.core.windows.net/storeimages/imagesDev/',
  // Get Privilige
  getPrivilege(id) {
    return Auth_api.get(`/privilege/getPrivilege/${encodedUserId(id)}`)
  },
  // To add new user
  createUser(id, body) {
    return Auth_api.post(`/users/${encodedUserId(id)}`, body);
  },

  // To add new user to us server
  createUserUS(id, body) {
    return Auth_api_us.post(`/users/${encodedUserId(id)}`, body);
  },

  // To add new user in firebase and system
  createFirebaseUser(id, body) {
    return Auth_api.post(`/creatreFirebaseUser/${encodedUserId(id)}`, body);
  },

  updateUser(id,body) {
    return Auth_api.post(`/updateFirebaseUser/${encodedUserId(id)}`, body);
  },

  updateUserUS(id,body) {
    return Auth_api_us.post(`/updateFirebaseUser/${encodedUserId(id)}`, body);
  },

  // To update user in firebase and system
  updateFirebaseUser(id, body) {
    return Auth_api.post(`/updateFirebaseUser/${encodedUserId(id)}`, body);
  },

  // Add Role
  addRole({ id, body }) {
    return Auth_api.post(`/role/addRole/${encodedUserId(id)}`, body)
  },

   // Get Roles by user
   getRoles(user_id) {
    return Auth_api.get(`/role/getRoles/${encodedUserId(user_id)}`);
  },

   // Get user List
   getUsers(user_id) {
    return Auth_api.get(`/getUsers/${encodedUserId(user_id)}`);
  },

  
   // Get user List
   getUsersByCompany(companyId) {
    return Auth_api.get(`/getUsersByCompany/${companyId}`);
  },

    // Get user details
    getUserById(user_id) {
      return Auth_api.get(`/getUserById/${encodedUserId(user_id)}`);
    },


    // Get company details
    getCompanyId(id) {
      return Auth_api.get(`/getCompanyById/${id}`);
    },

    getAllCompany(id) {
      return Auth_api.get(`companies/getAllCompany/${encodedUserId(id)}`);
    },

    // To add new company
  createCompany(id, body) {
    return Auth_api.post(`/companies/${encodedUserId(id)}`, body);
  },

     // To update company
     updateCompany(id, body) {
      return Auth_api.post(`/companies/update/${encodedUserId(id)}`, body);
    },

  // To add new store/company
  createStore(id, body) {
    return Auth_api.post(`/stores/addStore/${encodedUserId(id)}`, body);
  },

   // To add new store
   createStoreOnly(id, body) {
    return Auth_api.post(`/stores/addStoreOnly/${encodedUserId(id)}`, body);
  },

  // To add new store
  updateStore(id, body) {
    return Auth_api.post(`/stores/updateStore/${encodedUserId(id)}`, body);
  },

   // To add new store
   updateStorePolicy(id, body) {
    return Auth_api.post(`/stores/updateStorePolicy/${encodedUserId(id)}`, body);
  },



  // Get company by user
  getCompanyByUser(user_id) {
    return Auth_api.get(`/companies/getCompany/${encodedUserId(user_id)}`);
  },

  getState(user_id) {
    return Auth_api.get(`/states/${encodedUserId(user_id)}`);
  },

  // Get store by company
  getStoreByCompany(company_id) {
    return Auth_api.get(`/stores/getStoreByCompany/${company_id}`)
  },

  // Get store by user
  getStoreByUser(user_id) {
    return Auth_api.get(`/stores/getStore/${user_id}`)
  },

  getCitiesByState(user_id,stateId) {
    return Auth_api.get(`/cities/${encodedUserId(user_id)}/${stateId}`);
  },

  getAllCities(user_id) {
    return Auth_api.get(`/allCities/${encodedUserId(user_id)}`);
  },
   // To add new category
   createCategory(id, body) {
    return Auth_api.post(`/categories/${encodedUserId(id)}`, body);
  },

   // To add update category
   updateCategory(id, body) {
    return Auth_api.post(`/categories/update/${encodedUserId(id)}`, body);
  },

   // To get category
   getCategory(id, body) {
    return Auth_api.get(`/categories/${encodedUserId(id)}`);
  },

   // To get category by id
   getCategoriesById(useId,id) {
    return Auth_api.get(`/getCategoriesById/${encodedUserId(useId)}/${id}`);
  },

    // To get category by id
    getMainCategories(useId) {
      return Auth_api.get(`/mainCategoriesList/${encodedUserId(useId)}`);
    },

    getSubCategoriesList(userId,id) {
      return Auth_api.get(`/subCategoriesList/${encodedUserId(userId)}/${id}`);
    },

    // To get category by id
    getStoreById(id) {
      return Auth_api.get(`/stores/getStoreById/${id}`);
    },

      // check company Name Exist
      checkCompanyExist(companyName) {
    return Auth_api.get(`/checkCompanyExist/${companyName}`);
  },

   checkCorpIdExist(corpId) {
    return Auth_api.get(`/checkCorpIdExist/${corpId}`);
  },

  sendCorporationMail(userId,mobile,name,corpId) {
    return Auth_api.get(`/stores/sendCorporationMail/${encodedUserId(userId)}/${mobile}/${name}/${corpId}`);
  },

     // check company Name Exist
     checkBranchExist(branchName,companyId) {
      return Auth_api.get(`/stores/checkBranchExist/${branchName}/${companyId}`);
    },

    // To add new offer
  createOffer(id, body) {
    return Auth_api.post(`/offers/${encodedUserId(id)}`, body);
  },

    // To add new offer
    updateOffer(id, body) {
      return Auth_api.post(`/offers/update/${encodedUserId(id)}`, body);
    },

  // To active offer  campaign
  activeOfferCampaign(id, body) {
    return Auth_api.post(`/activeOfferCampaign/${encodedUserId(id)}`, body);
  },

  activeSponsoredOfferCampaign(id, body) {
    return Auth_api.post(`/activeSponsoredOfferCampaign/${encodedUserId(id)}`, body);
  },

  mapProductToActiveCamp(id, body) {
    return Auth_api.post(`/mapProductToOffer/${encodedUserId(id)}`, body);
  },

    // To active offer  campaign
    activeProductCampaign(id, body) {
      return Auth_api.post(`/activeProductCampaign/${encodedUserId(id)}`, body);
    },

    activeSponsoredProductCampaign(id, body) {
      return Auth_api.post(`/activeSponsoredProductCampaign/${encodedUserId(id)}`, body);
    },

    activeStoreCampaign(id) {
      return Auth_api.get(`/activeStoreCampaign/${id}`);
    },

    unactiveStoreCampaign(id) {
      return Auth_api.get(`/unactiveStoreCampaign/${id}`);
    },

       // To add new product
  createProduct(id, body) {
    return Auth_api.post(`/products/${encodedUserId(id)}`, body);
  },

    // To update product
    updateProduct(id, body) {
      return Auth_api.post(`/products/update/${encodedUserId(id)}`, body);
    },

  getCompanyOffer(companyId,status) {
    return Auth_api.get(`/getCompanyOffer/${companyId}/${status}`);
  },

  getCompanyOffer(companyId,status) {
    return Auth_api.get(`/getCompanyOffer/${companyId}/${status}`);
  },

  getCompanyBranchOffer(branchId,status) {
    return Auth_api.get(`/getCompanyBranchOffer/${branchId}/${status}`);
  },

  getCompanyProduct(companyId,status) {
    return Auth_api.get(`/getCompanyProduct/${companyId}/${status}`);
  },


  getShopifyCampignProductOnly(id,companyId) {
    return Auth_api.get(`/getShopifyCampignProductOnly/${id}/${companyId}`);
  },


  getShopifyCampignOffer(id,companyId,type) {
    return Auth_api.get(`/getShopifyCampignOffer/${id}/${companyId}/${type}`);
  },

  
  async configureShopifySponsoredCampaign(sectionBody) {
    return Auth_api.post(`/configureShopifySponsoredCampaign`, sectionBody);
  },


  getCompanyBranchProduct(branchId,status) {
    return Auth_api.get(`/getCompanyBranchProduct/${branchId}/${status}`);
  },

  getCompanyProductVariant(companyId,status,productId) {
    return Auth_api.get(`/getCompanyProductVariant/${companyId}/${status}/${productId}`);
  },

  getCompanyBranchProductVariant(branchId,status,productId) {
    return Auth_api.get(`/getCompanyBranchProductVariant/${branchId}/${status}/${productId}`);
  },

  getCampaignPlan(user_id,type) {
    return Auth_api.get(`/getCampaignPlan/${encodedUserId(user_id)}/${type}`);
  },

   // Get user details
   getOfferById(user_id,id) {
    return Auth_api.get(`/getOffersById/${encodedUserId(user_id)}/${id}`);
  },

  checkPromocode(code) {
    return Auth_api.get(`/checkPromocode/${code}`);
  },

  //Payment for offer campagain

   // Add Role
   makeOfferPayment(id, body) {
    return Auth_api.post(`/offerCampaignPayment/${encodedUserId(id)}`, body)
  },

    // To get category by id
    getStateById(id) {
      return Auth_api.get(`/stateById/${id}`);
    },


    // To add payment transacation
  createPaymentTransaction(id, body) {
    return Auth_api.post(`/paymentTransaction/${encodedUserId(id)}`, body);
  },


    // To add invoice
    createInvoice(id, body) {
      return Auth_api.post(`/invoice/${encodedUserId(id)}`, body);
    },

    // To add campagain
    createCampaign(id, body) {
      return Auth_api.post(`/campaign/${encodedUserId(id)}`, body);
    },
  getPrimaryOffer(user_id,companyId,storeId) {
    return Auth_api.get(`/getPrimaryOffer/${encodedUserId(user_id)}/${companyId}/${storeId}`);
  },

    // To get payment transactions of company
    getCompanyTransactions(companyId) {
    return Auth_api.get(`/getCompanyTransactions/${companyId}`);
  },

   // To get payment transactions of company branch
   getCompanyBranchTransactions(branchId) {
    return Auth_api.get(`/getCompanyBranchTransactions/${branchId}`);
  }, 
  getCompanyInvoice(companyId) {
    return Auth_api.get(`/getCompanyInvoice/${companyId}`);
  },

  getCompanyBranchInvoice(branchId) {
    return Auth_api.get(`/getCompanyBranchInvoice/${branchId}`);
  },

  getInvoiceById(id) {
    return Auth_api.get(`/getInvoiceById/${id}`);
  },

  getCompanyCampaign(companyId,status,type) {
    return Auth_api.get(`/getCompanyCampaign/${companyId}/${status}/${type}`);
  },

  getCompanyBranchCampaign(companyId,status,type) {
    return Auth_api.get(`/getCompanyBranchCampaign/${companyId}/${status}/${type}`);
  },

  getOfferBeacon(offerId) {
    return Auth_api.get(`/getOfferBeacon/${offerId}`);
  },

  getCampaignOffer(campaignId,type,dateFrom,dateTo) {
    return Auth_api.get(`/getCampaignOffer/${campaignId}/${type}/${dateFrom}/${dateTo}`);
  },

  getCampaignProduct(campaignId,type,dateFrom,dateTo) {
    return Auth_api.get(`/getCampaignProduct/${campaignId}/${type}/${dateFrom}/${dateTo}`);
  },

  async getCampaignOffer1(campaignId,type,dateFrom,dateTo) {
    return Auth_api.get(`/getCampaignOffer/${campaignId}/${type}/${dateFrom}/${dateTo}`);
  },

  async getCampaignProduct1(campaignId,type,dateFrom,dateTo) {
    return Auth_api.get(`/getCampaignProduct/${campaignId}/${type}/${dateFrom}/${dateTo}`);
  },

  getCampaignById(campaignId) {
    return Auth_api.get(`/getCampaignById/${campaignId}`);
  },

  // To get product by id
  getProductById(user_id,id) {
    return Auth_api.get(`/products/${encodedUserId(user_id)}/${id}`);
  },

  
  createProductCopy(id) {
    return Auth_api.get(`/createProductCopy/${id}`);
  },

  createOfferCopy(id) {
    return Auth_api.get(`/createOfferCopy/${id}`);
  },

  getProductsByOffer(user_id,id) {
    return Auth_api.get(`/products/offers/${encodedUserId(user_id)}/${id}`);
  },

  getOfferProducts(id) {
    return Auth_api.get(`/getOfferProducts/${id}`);
  },

  getBillsByCompany(companyId,status) {
    return Auth_api.get(`/billsByCompany/list/${companyId}/${status}`);
  },

  getBillsByBranch(companyId,status) {
    return Auth_api.get(`/billsByBranch/list/${companyId}/${status}`);
  },

  getSectionList(status) {
    return Auth_api.get(`/getSectionList/${status}`);
  },

  getSectionById(id) {
    return Auth_api.get(`/getSectionById/${id}`);
  },

  createSection(id, body) {
    return Auth_api.post(`/addSection/${encodedUserId(id)}`, body);
  },

  changeBillReviewStatus(status,statusMsg,billId,userId) {
    return Auth_api.post(`/bills/status/${status}/${statusMsg}/${billId}/${encodedUserId(userId)}`);
  },

  changeSectionStatus(id,sectionId,status) {
    return Auth_api.get(`/updateSectionStatus/${encodedUserId(id)}/${sectionId}/${status}`);
  },

  getRatingByBillId(id) {
    return Auth_api.get(`/ratingByBillId/${id}`);
  },

  getSectionByCategory(id,pageId) {
    return Auth_api.get(`/getSectionListByCategory/${id}/${pageId}`);
  },

    // To get payment transactions of company
    getCompanyProductCatalog(companyId) {
      return Auth_api.get(`/getCompanyCatalogProduct/${companyId}`);
    },

    getBranchProductCatalog(companyId) {
      return Auth_api.get(`/getCompanyBranchCatalogProduct/${companyId}`);
    },

    getProductsByCataLog(id) {
      return Auth_api.get(`/getProductsByCataLog/${id}`);
    },

    getCollectionByCataLog(id) {
      return Auth_api.get(`/getCollectionByCataLog/${id}`);
    },

    createPaymentIntent(userId,price) {
      return Auth_api.get(`create-payment-intent/${encodedUserId(userId)}/${price}`);
    },

    updatePaymentIntent(userId,price,paymentIntent) {
      return Auth_api.get(`update-payment-intent/${encodedUserId(userId)}/${price}/${paymentIntent}`);
    },

    payWithExistingCard(userId,price,cardId) {
      return Auth_api.get(`payWithExistingCard/${encodedUserId(userId)}/${price}/${cardId}`);
    },

    addStripCardToCustomer(userId,paymentId) {
      return Auth_api.get(`addStripCardToCustomer/${encodedUserId(userId)}/${paymentId}`);
    },

    async deleteCustomerCard(userId,cardId) {
      return Auth_api.get(`deleteCustomerCard/${encodedUserId(userId)}/${cardId}`);
    },

    getCustomerCardList(userId) {
      return Auth_api.get(`getCustomerCardList/${encodedUserId(userId)}`);
    },


    getStoreShopifyBillReport(companyId,branchId,dateFrom,dateTo) {
      return Auth_api.get(`/stores/getStoreShopifyBillReport/${companyId}/${branchId}/${dateFrom}/${dateTo}`);
    },

    getShopifyOrderByBill(billId) {
      return Auth_api.get(`/getShopifyOrderByBill/${billId}`);
    },

  async uploadCompanyLogo (formData, companyName,storeId ) {
    try {
      // let response = await axios.post(`${API_URL}/uploadImage/${storeId}/${imageName}`, formData)
      let response = Auth_api.post(`/uploadCompanyImage/${companyName}/${storeId}`, formData)
     // store.state.logoUrl = bucketURL.concat(response.data)
      return response
    } catch (e) {
      this.errors.push(e)
    }
  },

  async uploadOfferImage (formData, companyName,storeId,offerId ) {
    try {
      // let response = await axios.post(`${API_URL}/uploadImage/${storeId}/${imageName}`, formData)
      let response = Auth_api.post(`/uploadOfferImage/${companyName}/${storeId}/${offerId}`, formData)
     // store.state.logoUrl = bucketURL.concat(response.data)
      return response
    } catch (e) {
      this.errors.push(e)
    }
  },

  async uploadCategoryImage (formData, folder,imageName ) {
    try {
      // let response = await axios.post(`${API_URL}/uploadImage/${storeId}/${imageName}`, formData)
      let response = Auth_api.post(`/uploadImage/${folder}/${imageName}`, formData)
     // store.state.logoUrl = bucketURL.concat(response.data)
      return response
    } catch (e) {
      this.errors.push(e)
    }
  },

}

export default API
